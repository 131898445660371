<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸科技受邀出席“第十五届HED峰会”
              </p>
              <div class="fst-italic mb-2">2023年12月20日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >HED峰会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/67/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    12月6日，由财视中国主办的“第十五届HED峰会”在深圳圆满举行，非凸科技首席品牌官郑媛姿受邀出席峰会并主持专题圆桌讨论。与南方基金数量化投资部总经理冯雨生、兴银基金指数与量化投资部总经理陈龙、稳博投资总经理钱晓珺、致诚卓远副总经理马海源、港交所市场发展科内地客户发展部副总裁郭菲共同进行了深度交流。
                  </p>
                  <p>
                    在今年行业整体缩量的背景下，量化行业规模仍然维持了环比小幅增长。冯雨生表示，今年量化投资备受关注，同时也存在一些争议。从正面角度来看，量化策略作为一种数据驱动的投资方式，系统化地将市场观点转化为超额收益，为投资者提供了独特收益和风险的策略，能够分散资产配置风险，对市场流动性也有益处。其实在这个过程中，量化投资经理主观判断的因素较少，更多是由数据来驱动。作为量化从业人员，将积极与监管机构及公众沟通，确保市场交易的公平和透明；同时也将继续努力提供优质的量化策略，为投资者创造更多价值。在风险控制方面，通过分散持仓、减少主观干预的风险以及利用表现较好的因子投资等，量化投资能够为投资者提供风险收益比更好的投资组合。
                  </p>
                  <p>
                    陈龙认为，当前市场环境更偏向于低位震荡存量博弈状态，主动投资可能面临着较低的收益预期，相较之下，量化基金除了可能获得持有性阿尔法收益外，还会获得交易性收益。在风险控制方面，量化投资具有严格的纪律性与透明度，在遇到回撤时，会及时停下来重新审视策略，这种策略迭代过程有助于及时发现并规避潜在风险。在资产配置方面，量化投资也可以通过在大盘、中盘和小盘股票板块之间，通过差异化的策略配置来分散风险。
                  </p>
                  <p>
                    钱晓珺表示，量化投资更多地应该被视为一种分析方式，而非交易行为。量化主流策略包括指数增强和中性策略，超额收益主要来源于选股而非多次频繁交易。此外，今年由于基本面投资的难度和压力较大，很多投资者开始将目光投向了量化投资领域，希望通过量化策略来获取更好的投资回报。同时，他也强调，虽然行业关注度增加，但量化投资并非易事，持仓分散和严格的风控措施依然必不可少。
                  </p>
                  <p>
                    马海源表示，量化是数字时代人工智能技术行业应用的低垂果实。量化私募受益于上述先进技术的使用，在为投资人管理风险创造收益的同时，也需要保持平常心，理解作为新物种或者新业态受到关注甚至质疑，要向投资人做充分的沟通和解释。坚持使用先进技术持续对量化交易体系进行性能调优，为投资人创造价值。
                  </p>
                  <p>
                    郭菲分享了香港市场为国内量化基金带来的新机遇。近年以来，香港交易所推出了一系列与国内资产挂钩的衍生品品种，以满足投资者的风险管理需求。同时，也越来越多量化机构开始放眼海外市场，并利用香港丰富的金融产品和资金沉淀，进行全球化布局。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/67/02-现场.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    感谢财视中国的盛邀，有机会与行业专家共同探讨和交流。未来，非凸科技将持续秉承着赋能交易的初心，不断革新技术，助力推动数智交易行业高质量可持续发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News67",
};
</script>

<style></style>
